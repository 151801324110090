<template>
    <div class="medicalReport-main">
        <div class="List-box">
            <!-- <div class="list-title">
                <p class="title">我的报告</p>
            </div> -->
            <div class="list-mian" :class="{'no-main':medicalReportList.length == 0}">
                <template v-for="(item,index) in medicalReportList">
                    <div class="list-item check-item"   :key="index" :class="item.gender==2? 'womanColor':'manColor'">
                        <div class="check-box" @click="setCheck(index)" :class="{'check-active':item.checktype}"></div>
                        <div  @click="goDetails(item)">
                            <p class="text">{{item.peDate}}</p>
                            <p class="text">{{item.tenantName}}</p>
                            <p class="text">{{item.peCode}}</p>
                            <div class="postName">
                                <span class="name">{{item.peUserName}}</span>
                                <p class="sex"><i :class="item.gender==2?'female':'male'"></i><span>{{item.gender==2?$t("pe.female"):$t("pe.male")}}</span></p>
                            </div>
                            <img v-if="item.isNew == 1" class="new-icon" src="@/assets/img/isnew_icon.png">
                        </div>
                    </div>
                </template>
                <div class="no-data" v-show="medicalReportList.length == 0">
                    <img src="@/assets/img/data-null.png" class="img-null">
                    <p class="text-null">
                        <span >{{$t('pe.noMedicalReport')}}</span>
                    </p>
                </div>
            </div>
            <div class="contrast-btn" :class="{'picon':activeId.length==2}" @click="btnContrast">
                {{$t("pe.compareReport")}}
            </div>
        </div>
    </div>
</template>

<script>
import { medicalReportList } from "@/api/medicalReport";
export default {
    data() {
        return {
            medicalReportList:[],
            // 选中id
            activeId:[],
        }
    },
    created(){
        document.title = this.$t("pe.medicalContrast");
        this.getMedicalReortData();
    },
    methods:{
        // 获取列表数据
        async getMedicalReortData(){
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            let datadd = {};
            let result = await medicalReportList(datadd);
            toast.clear();
            if(result.data.errorCode == 0){
                for (let i = 0; i < result.data.data.length; i++) {
                    result.data.data[i].checktype = false;
                }
                this.medicalReportList = result.data.data;
            }
            // console.log(result);
            // if(result.)
        },
        setCheck(index){
            if(this.activeId.length<2){
                this.medicalReportList[index].checktype = !this.medicalReportList[index].checktype;
                if(this.medicalReportList[index].checktype){
                    this.activeId.push(this.medicalReportList[index].peCode);
                }else{
                    this.activeId.splice(this.activeId.indexOf(this.medicalReportList[index].peCode,1));
                }
                console.log(this.activeId);
            }else{

                if(this.medicalReportList[index].checktype){
                    this.medicalReportList[index].checktype = !this.medicalReportList[index].checktype;
                    this.activeId.splice(this.activeId.indexOf(this.medicalReportList[index].peCode,1));
                }else{
                    // this.$toast("最多选择两项");
                }
                // this.medicalReportList[index].checktype = !this.medicalReportList[index].checktype;
                // if(!this.medicalReportList[index].checktype){
                //     this.activeId.splice(this.activeId.indexOf(this.medicalReportList[index].peCode,1));
                // }else{
                //     for (let i = 0; i < this.medicalReportList.length; i++) {
                //         if(this.medicalReportList[i].peCode == this.activeId[0]){
                //             this.medicalReportList[i].checktype = false;
                //             this.activeId.splice(0,1);
                //             break;
                //         }
                //     }
                //     this.activeId.push(this.medicalReportList[index].peCode);
                // }

            }
        },
        // 查看详情
        goDetails(item){
            this.$router.push({
                path:"/pe/detail",
                query:{
                    peCode:item.peCode
                }
            })
        },
        btnContrast(){
            if(this.activeId.length<2){
                return false;
            }
            this.$router.push({
                path:"/pe/compare-detail",
                query:{
                    peCodes:this.activeId
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.medicalReport-main{
    background-color: #ECEEF4;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    .List-box{
        padding: .2rem .6rem;
        flex: 1;
        background-color:#fff;
        display: flex;
        overflow: hidden;
        flex-direction: column;
        .list-title{
            display: flex;
            .title{
                font-size: .64rem;
                font-family: PingFang SC;
                font-weight: 600;
                color: #303133;
                line-height: .64rem;
                margin:.6rem 0;
                padding-left: .3rem;
                position: relative;
                &::after{
                    content: ' ';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    margin-top: -.2rem;
                    width: .1rem;
                    height: .54rem;
                    background: #3988FF;
                    border-radius: 0.15rem;
                }
            }
        }
        .list-mian{
            height:calc(100% - 2.64rem);
            overflow-y: scroll;
            .check-item{
                margin-top: 1.64rem;
            }
            .list-item{
                height: 4.75rem;
                background:url("~@/assets/img/tj_list_item_back.png") no-repeat;
                background-size: 100% 100%;
                margin-bottom: .6rem;
                box-sizing: border-box;
                padding-top: 1.8rem;
                padding-left: 1rem;
                position: relative;
                .check-box{
                    position: absolute;
                    top: -1.2rem;
                    left: .3rem;
                    height: 1rem;
                    width: 1rem;
                    border: .04rem solid #AAAAAA;
                    border-radius: .08rem;
                }
                .check-active{
                    border: 0;
                    background: url("~@/assets/img/tj_duibi_active.png") no-repeat;
                    background-size: 100% 100%;
                }
                .text{
                    font-size: .52rem;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #027DC4;
                    line-height: .8rem;
                }
                .postName{
                    display: flex;
                    max-width: max-content;
                    position: absolute;
                    right: .9rem;
                    top: .9rem;
                    font-size: .64rem;
                    font-family: PingFang SC;
                    font-weight: 600;
                    color: #4CAAFF;
                    line-height: .8rem;
                    .name{
                        width: 1.92rem;
                        display: block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .male{
                        background-image: url("~@/assets/img/male.png");
                    }
                    .female{
                        background-image: url("~@/assets/img/female.png");
                    }
                    .sex{
                        font-size: .48rem;
                        display: flex;
                        align-items: flex-end;
                        line-height: .7rem;
                        i{
                            width: .4rem;
                            height: .4rem;
                            background-size: 100% 100%;
                            display: block;
                            margin: 0 .4rem;
                            margin-right: .1rem;
                            margin-bottom: .1rem;
                        }
                        
                    }
                }
                .new-icon{
                    position: absolute;
                    width: 2.1rem;
                    height: 1.68rem;
                    bottom: 0.1rem;
                    right: .16rem;
                }
            }
            .womanColor{
                .postName{
                    color: #FF5D9E;
                }
            }
        }
        .contrast-btn{
            width: 100%;
            height: 2.2rem;
            background: #C1C4CB;
            border-radius: 1.1rem;
            margin:.3rem 0;
            font-size: .64rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #919398;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: .5rem;
        }
        .picon{
            opacity: 1;
            background: #49A7FF;
            box-shadow: 0 0.3rem 0.75rem 0 rgba(73, 167, 255, 0.53);
            color: #FEFEFF;        
        }
        .no-main{
            display: flex;
            justify-content: center;
            align-items: center;
            .no-data{
                // height: ;
                display: flex;
                align-items: center;
                flex-direction: column;
                .img-null{
                    height: 4.8rem;
                    width: 5.6rem;
                    margin-bottom: 1.4rem;
                }
                .text-null{
                    font-size: .52rem;
                    font-family: PingFang SC;
                    font-weight: 600;
                    color: #C1C4CB;
                    line-height: .8rem;
                    .btn{
                        color: #5C9DF8;
                    }
                }
            }
        }
    }
}
</style>