import request from '../utils/request'

// 体检报告 列表
export function medicalReportList(data) {
    return request({
        url: "/pe/list",
        method: "post",
        data
    })
}

// 体检报告 列表查询
export function medicalReportSearch(data) {
    return request({
        url: "/pe/query-new",
        method: "post",
        data
    })
}

// 体检报告 APP用的 - 王老师
export function medicalPeGet(data) {
    return request({
        url: "/pe/get",
        method: "post",
        data
    })
}
// 体检报告 对比详情
export function compareDetails(data) {
    return request({
        url: "/pe/compare",
        method: "post",
        data
    })
}

// 体检报告 报告详情
export function getDetails(data) {
    return request({
        url: "/pe/get",
        method: "post",
        data
    })
}